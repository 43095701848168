import { useState, useContext, useEffect } from 'react'
import DataContext from '../../../store/DataContext';

import { FormGroup, Checkbox, FormControlLabel, Input } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import NewTermImg from '../../../assets/img/ad/new-term.svg'

export default function Step6({ setDisableBtn }) {


  const dataCtx = useContext(DataContext);

  const termArr = ["term 1", "term 2", "term 3"];

  const [selected, setSelected] = useState([]);
  const [allTerms, setAllTerms] = useState(termArr);
  const [newTerm, setNewTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [maxLimit, setMaxLimit] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
  };

  // useEffect(() => {
  //   handlePushToContext()
  // }, [selected])

  useEffect(() => {
    setDisableBtn(false)
  }, [])



  const deleteItem = (index) => () => {
    setAllTerms((allTerms) => allTerms.filter((_, i) => i !== index));
  }

  //   const putAllTerms = (e) => {
  //       let newItem = e.target.value
  //       //allTerms.indexOf(newItem) === -1 ? setAllTerms((allTerms) => [...allTerms, newItem]) : null;
  //   }

  const putNewTerm = (e) => {
    let term = e.target.value
    setNewTerm(term)
    if (newTerm.length > 100) {
      setMaxLimit(true)
    } else {
      setMaxLimit(false)
    }
  }

  const addNewTerm = () => {
    if (newTerm && newTerm.length <= 100) {
      allTerms.push(newTerm)
    }
    setNewTerm("")
    handleCloseModal()

  }

  // pushes final array in context
  const handlePushToContext = () => {
    dataCtx.step6 = {
      terms: selected
    }
  }

  // adds to the final array
  const setSelectedArray = (e, label) => {
    if (e.target.checked) {
      dataCtx.setStep6({ terms: [...dataCtx.step6.terms, label] })
      // setSelected(prev => [...prev, label])
    } else {
      removeDuplicates(label)
    }
  }

  // remove the term if deselected 
  function removeDuplicates(label) {
    let tempArray = dataCtx.step6.terms?.slice()
    const index = dataCtx.step6.terms.indexOf(label);
    tempArray.splice(index, 1);
    dataCtx.setStep6({ terms: tempArray })
  }

  const checkboxTermsSelected = (label) => {

    const index = dataCtx.step6.terms.indexOf(label);

    if (index != -1) {
      return true
    } else {
      return false
    }

  }
  console.log(dataCtx)

  return (
    <>
      <div className="step6-container">
        <div className="step6">
          <div className="heading">
            <h1>Terms and conditions</h1>
            <h3>Select the terms that apply to your coupon or create a new one</h3>
          </div>
          <FormGroup className="select-terms" >
            {
              allTerms?.map((item, i) => <div key={i} className="term"><FormControlLabel onClick={(e) => setSelectedArray(e, item)} control={<Checkbox checked={checkboxTermsSelected(item)} />} label={item} /> <CloseRoundedIcon className="close" onClick={deleteItem(i)} /></div>)
            }
          </FormGroup>
          <div className="create-new" onClick={handleOpenModal}>
            <AddRoundedIcon /> <span>Create New One</span>
          </div>
        </div>
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="new-term-container">
              <div className="new-term">
                <div className="heading">
                  <h1>Add a new term or condition</h1>
                  <h3>Enter just 1 term or condition at a time</h3>
                </div>
                <div className="image">
                  <img src={NewTermImg} width={200} height={200} alt="new term" />
                </div>
                <div className="input">
                  <Input value={newTerm} placeholder="Ex: Deliva reserves the right to discontinue a coupon at any time" onChange={putNewTerm} />
                  <span className={maxLimit ? "helper-text warning" : "helper-text"} >MAX 100 CHARACTERS</span>
                </div>
                <div className="blue-btn">
                  <Button onClick={addNewTerm} disabled={maxLimit} style={{ marginTop: '10px' }} ><AddRoundedIcon /> <span>Add</span></Button>
                </div>
                <div className="btn-close ">
                  <CloseRoundedIcon onClick={handleCloseModal} />
                </div>
              </div>
            </div>
          </Box>
        </Modal>

      </div>

    </>
  )
}

