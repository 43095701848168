import { useState, useContext, useEffect } from 'react'
import DataContext from '../../../store/DataContext';

import { FormGroup, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Checkbox } from "@mui/material";
import { ExpandMoreRounded } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function Step8({ setDisableBtn }) {

  const dataCtx = useContext(DataContext);

  const [selected, setSelected] = useState([]);
  const [categories, setCategories] = useState([]);


  useEffect(() => {
    setDisableBtn(false)
    getCategories()
  }, [])

  // const deleteItem = (index) => {
  //   setSelected((selected) => selected.filter((_, i) => i !== index));
  // }

  // pushes final array in context
  // const handlePushToContext = () => {
  //   dataCtx.step8 = {
  //     categories: selected
  //   }
  // }

  // adds to the final array
  const putSelected = (e, item) => {
    // let newItem = e.target.value
    if (e.target.checked) {
      // setSelected((prev) => [...prev, newItem])
      let tempArr = dataCtx.step8.categories
      tempArr.push(item)
      dataCtx.setStep8({ categories: tempArr })
    } else {
      removeDuplicates(item)
    }
    // setSelected(...selected)
    console.log('in putSelected')
  }

  // remove the term if deselected 
  // function removeDuplicates(label) {
  //   const index = selected.indexOf(label);
  //   // selected.splice(index, 1); 
  //   setSelected((selected) => selected.filter((_, i) => i !== index))
  // }

  const removeDuplicates = (item) => {

    const index = dataCtx.step8.categories.indexOf(item.id);
    const tempArray = dataCtx.step8.categories.slice()
    tempArray.splice(index, 1);
    dataCtx.setStep8({ categories: tempArray })

  }

  const categoryCheckboxCheck = (id) => {

    // const index = dataCtx.step8.categories.indexOf(label);
    for (let i = 0; i < dataCtx.step8.categories; i++) {
      if (dataCtx.step8.categories[i].id == id) {
        return true
      } else {
        return false
      }
    }
    console.log('in putSelected')

  }

  const getCategories = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/categories`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      const data = await res.json()

      if (data.status == 'success') {
        setCategories(data.data)
        console.log(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getSubCategory = (sub) => {
    return sub.map((item, i) => (
      <FormControlLabel key={i + 'asdf'} control={<Checkbox checked={!!dataCtx.step8.categories?.find(x => x.id === item.id)} onClick={(e) => putSelected(e, item)} value={item.title} />} label={item.title} />
    ))
  }

  // console.log(selected)
  console.log(dataCtx)

  return (
    <>
      <div className="step8-container">
        <div className="step8">
          <div className="heading">
            <h1>Categories</h1>
          </div>
          {
            dataCtx.step8.categories.length
              ? <div className="selected-categories">
                {
                  dataCtx.step8.categories.map((item, i) => <div className='categories' key={i} onClick={() => removeDuplicates(item)} > <span>{item.title}</span> {/*<CloseRoundedIcon onClick={() => deleteItem(i)} />*/} </div>)
                }
              </div>
              : null
          }
          <div className="select-categories" >
            {
              categories?.length > 0
                ? categories?.slice(0, 100).map((item, index) => (
                  <Accordion key={index} >
                    <AccordionSummary
                      expandIcon={<ExpandMoreRounded />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <span>{item.title}</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <FormGroup>
                          {getSubCategory(item.subCategories)}
                          {/* <FormControlLabel control={<Checkbox checked={!!dataCtx.step8.categories?.find(x => x.id === item.id)} onClick={(e) => putSelected(e, item)} value={item.title} />} label={item.title} /> */}
                        </FormGroup>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))
                : null
            }
          </div>
        </div>

      </div>
    </>
  )
}

