import { useState, useContext, useEffect } from 'react'
import { Input } from '@mui/material'
import DataContext from '../../../store/DataContext';

export default function Step3({ setDisableBtn }) {


  const dataCtx = useContext(DataContext);

  // const [coupon1Deal, setCoupon1Deal] = useState(1);
  // const [coupon1Type, setCoupon1Type] = useState("T-Shirt");
  // const [coupon2Deal, setCoupon2Deal] = useState(10);
  // const [coupon3Deal, setCoupon3Deal] = useState(100);


  useEffect(() => {
    setDisableBtn(false)
    // dataCtx.setStep3({ activeTab: 1, data: { type: 'T-Shirt', deal: 1 }, data: { deal: 10 }, data: { deal: 100 } })
  }, [])


  const handleCoupon1Deal = (e) => {
    let newDeal = e.target.value
    dataCtx.setStep3({ ...dataCtx.step3, data: { ...dataCtx.step3.data, deal: newDeal } })
    // setCoupon1Deal(newDeal)
    // submit()
  }
  const handleCoupon1Type = (e) => {
    let newType = e.target.value
    dataCtx.setStep3({ ...dataCtx.step3, data: { ...dataCtx.step3.data, dealItem: newType } })
    // setCoupon1Type(newType)
    // submit()
  }
  const handleCoupon2 = (e) => {
    let newDeal = e.target.value
    dataCtx.setStep3({ ...dataCtx.step3, data: { ...dataCtx.step3.data, deal: newDeal } })
    // setCoupon2Deal(newDeal)
    // submit()
  }
  const handleCoupon3 = (e) => {
    let newDeal = e.target.value
    dataCtx.setStep3({ ...dataCtx.step3, data: { ...dataCtx.step3.data, deal: newDeal } })
    // setCoupon3Deal(newDeal)
    // submit()
  }

  // const submit = () => {
  //   if (activeTab === 1) {
  //     dataCtx.setStep3({
  //       free: coupon1Type,
  //       deal: coupon1Deal
  //     })
  //   } else if (activeTab === 2) {
  //     dataCtx.setStep3({
  //       off: coupon2Deal,
  //     })
  //   } else if (activeTab === 3) {
  //     dataCtx.setStep3({
  //       rands: coupon3Deal,
  //     })
  //   }
  //   setDisableBtn(false)
  //   console.log(dataCtx)
  // }

  // console.log(dataCtx.step3.data?.type)

  const changeActiveTab = (tab, dealUnit) => {
    dataCtx.setStep3({ ...dataCtx.step3, activeTab: tab, data: { ...dataCtx.step3.data, dealUnit: dealUnit } })
  }


  return (
    <>
      <div className="coupon-select-container">

        <div className="coupon-select-header">
          <h1>Coupon and custom your deal</h1>
          <h3>Select and custom your deal</h3>
        </div>

        <div className="coupon-container">
          <div className="coupon-card" style={dataCtx.step3.activeTab === 1 ? { border: "2px solid #2b1de8" } : { filter: "saturate(0%)", color: "#c4c4ce" }} onClick={() => changeActiveTab(1, 'free')}>
            <div className="coupon-head" >
              <h1>{dataCtx.step3.activeTab === 1 ? dataCtx.step3.data?.deal : 1}</h1>
              <span>{dataCtx.step3.data?.dealItem}</span>
              <h2>FREE</h2>
            </div>
            <div className="coupon-inputs">
              <Input value={dataCtx.step3.activeTab === 1 ? dataCtx.step3.data?.deal : 1} onChange={(e) => handleCoupon1Deal(e)} />
              <Input value={dataCtx.step3.data?.dealItem} onChange={(e) => handleCoupon1Type(e)} />
              <span className='coupon-bottom-text'>FREE</span>
            </div>

            <div className="coupon-select" style={dataCtx.step3.activeTab === 1 ? { background: "#2b1de8" } : null}>

            </div>
          </div>

          <div className="coupon-card" style={dataCtx.step3.activeTab === 2 ? { border: "2px solid #2b1de8" } : { filter: "saturate(0%)", color: "#c4c4ce" }} onClick={() => changeActiveTab(2, 'off')}>
            <div className="coupon-head" >
              <h1 style={{ marginBottom: "0" }} >{dataCtx.step3.activeTab === 2 ? dataCtx.step3.data?.deal : 10}%</h1>
              <h2 style={{ marginTop: "0px" }}>OFF</h2>
            </div>

            <div className="coupon-inputs">
              <Input value={dataCtx.step3.activeTab === 2 ? dataCtx.step3.data?.deal : 10} style={{ marginTop: "50px" }} onChange={(e) => handleCoupon2(e)} />
              <span className='coupon-bottom-text'>% OFF</span>
            </div>

            <div className="coupon-select" style={dataCtx.step3.activeTab === 2 ? { background: "#2b1de8" } : null}>

            </div>
          </div>

          <div className="coupon-card" style={dataCtx.step3.activeTab === 3 ? { border: "2px solid #2b1de8" } : { filter: "saturate(0%)", color: "#c4c4ce" }} onClick={() => changeActiveTab(3, 'rands')}>
            <div className="coupon-head">
              <h1 style={{ marginBottom: "0" }} >{dataCtx.step3.activeTab === 3 ? dataCtx.step3.data?.deal : 100}</h1>
              <h2 style={{ marginTop: "0px" }} >RANDS</h2>
            </div>

            <div className="coupon-inputs">
              <Input value={dataCtx.step3.activeTab === 3 ? dataCtx.step3.data?.deal : 100} style={{ marginTop: "50px" }} onChange={(e) => handleCoupon3(e)} />
              <span className='coupon-bottom-text'>RANDS</span>
            </div>

            <div className="coupon-select" style={dataCtx.step3.activeTab === 3 ? { background: "#2b1de8" } : null}>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}