import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import ThanksImg from "../../assets/img/ad/deliva_blue_logo.svg";

export default function Thanks () {
    return (
      <div className="container">
      <div className="thanks-container">
        <div className="thanks">

            <div className="thanks-img">
                <img src={ThanksImg} alt="thanks"/>
            </div>

            <div className="thanks-text">
                <h1>Thanks Steve Mcqueen</h1>
                <h3>One of our agents will be contacting you soon <br /> to create your coupon.</h3>
            </div>

            <div className="light-blue-btn">
              <NavLink to="/">
                <Button>Home</Button>
              </NavLink>
            </div>
        </div>
        <div className="logo-home"></div>
      </div>
      </div>

    )
  }
 