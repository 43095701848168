import { createContext, useState } from 'react';

const DataContext = createContext({

  step1: { companyName: '', email: '', managerName: '', managerAddress: '', managerNumber: '', companyWebsite: '' },
  step2: {},
  step3: { activeTab: 1, data: { deal: 1, dealUnit: "Free", dealItem: 'T-Shirt' } },
  step4: { cost: 0, durationType: 'days', duration: 0, couponDescription: '' },
  step5: { couponCode: '', couponWebsite: '' },
  step6: { terms: [] },
  step7: { locations: [] },
  step8: { categories: [] },
  setStep1: (step1) => { },
  setStep2: (step2) => { },
  setStep3: (step3) => { },
  setStep4: (step4) => { },
  setStep5: (step5) => { },
  setStep6: (step6) => { },
  setStep7: (step7) => { },
  setStep8: (step8) => { },
  setStep9: (step9) => { },
});

export function DataContextProvider(props) {

  const [step1, setStep1] = useState({ companyName: '', email: '', managerName: '', managerAddress: '', managerNumber: '', companyWebsite: '' });
  const [step2, setStep2] = useState({});
  const [step3, setStep3] = useState({ activeTab: 1, data: { deal: 1, dealUnit: "Free", dealItem: 'T-Shirt' } });
  const [step4, setStep4] = useState({ cost: 0, durationType: 'days', duration: 0, couponDescription: '' });
  const [step5, setStep5] = useState({ couponCode: '', couponWebsite: '' });
  const [step6, setStep6] = useState({ terms: [] });
  const [step7, setStep7] = useState({ locations: [] });
  const [step8, setStep8] = useState({ categories: [] });



  function setStep1Handler(data) {
    setStep1(data);
  }
  function setStep2Handler(data) {
    setStep2(data);
  }
  function setStep3Handler(data) {
    setStep3(data);
  }
  function setStep4Handler(data) {
    setStep4(data);
  }
  function setStep5Handler(data) {
    setStep5(data);
  }
  function setStep6Handler(data) {
    setStep6(data);
  }
  function setStep7Handler(data) {
    setStep7(data);
  }
  function setStep8Handler(data) {
    setStep8(data);
  }


  const context = {
    step1: step1,
    step2: step2,
    step3: step3,
    step4: step4,
    step5: step5,
    step6: step6,
    step7: step7,
    step8: step8,
    setStep1: setStep1Handler,
    setStep2: setStep2Handler,
    setStep3: setStep3Handler,
    setStep4: setStep4Handler,
    setStep5: setStep5Handler,
    setStep6: setStep6Handler,
    setStep7: setStep7Handler,
    setStep8: setStep8Handler,
  };

  return <DataContext.Provider value={context}>
    {props.children}
  </DataContext.Provider>
}

export default DataContext;