import { ArrowBackIosNewRounded } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

const HelpWithCoupons = () => {

  const params = useParams()

  const [term, setTerm] = useState("")

  useEffect(() => {
    setTerm(params.term)
  }, [params])

  // console.log(params.term)

  return (
    <div className="yes-no-container">
      <div className="yes-no-left">
        <div className="yes-no">
          <p>Do you want us to <br /> <strong>help you create</strong><br /> the coupon ?</p>
          <div className="btn-wrapper">
            <div className="blue-btn button">
              <NavLink to={`/advertise/${term}/help-me`}>
                <Button>Yes</Button>
              </NavLink>
            </div>
            <div className="blue-border-btn button">
              <NavLink to={`/advertise/coupon/${params.term}/create-coupon/1?edit=false`}>
                <Button>No</Button>
              </NavLink>
            </div>
          </div>
          {/* <p>Lorem ipsum dolor sit amet.</p> */}
        </div>
      </div>
      <div className="yes-no-right"></div>
      <div className="logo-home"></div>
      {/* <NavLink className="back-link" to="/price-plan"><span > <ArrowBackIosNewRounded /> Back </span></NavLink> */}
    </div>
  )
}

export default HelpWithCoupons