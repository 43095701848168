import React, { useState, useContext, useEffect, useCallback } from 'react';
import upload from '../../../assets/img/ad/imageUpload.svg'
import Step2a from './Step2a';
import Step2b from './Step2b';
import Step2c from './Step2c';
import DataContext from '../../../store/DataContext';

import Dropzone from '../../ImageDropdown/Dropzone';

//firebase
import { uploadBytes, ref as sRef, database } from "../../Firebase/FirebaseConfig";
import storage from "../../Firebase/FirebaseConfig";
import { getDownloadURL } from 'firebase/storage';


export default function Step2({ setDisableBtn, setCompanyId, companyId }) {


  const dataCtx = useContext(DataContext);

  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const [failed, setFailed] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    setCompanyId(+query.get('companyId'))
  }, [])

  useEffect(() => {

    if (image) {
      uploadLogoImg()
      // submit()
    } else {
      setDisableBtn(true)
    }
    
    setDisableBtn(false)
  }, [image])

  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    setImage(acceptedFiles[0]);
    // acceptedFiles.map((file) => {
    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     setImage(e.target.result);
    //   };
    //   reader.readAsDataURL(file);
    //   return file;
    // });
  }, []);

  const handleFile = (e) => {
    let img = e.target.files[0];
    setImage(img);
    // console.log(e)
    // if (img.type === "image/png" || img.type === "image/jpeg") {
    //   setLoading(true)
    //   setImage(img)
    //   submit(img)
    //   setLoading(false)
    //   setDisableBtn(false)
    // } else {
    //   setDisableBtn(true)
    //   setFailed(true)
    // }
  }

  // const submit = (img) => {
  //   dataCtx.step2 = {
  //     img: img
  //   }
  //   console.log(dataCtx)
  // }



  // upload image to firebase and then to PUT in blog object
  const uploadLogoImg = async () => {

    if (image) {

      setIsUploading(true)
      let coverRef = `/coupon/${image.name}`;
      // console.log(coverRef)
      const storageRef = sRef(storage, coverRef)

      await uploadBytes(storageRef, image).then((snapshot) => {

        getDownloadURL(storageRef)
          .then(async (imageUrl) => {

            dataCtx.setStep2({
              "logoXlg": imageUrl,
              "logoLg": imageUrl,
              "logoMd": imageUrl,
              "logoSm": imageUrl,
              "logoXs": imageUrl
            })

            const response = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company/${companyId}/logo`, {
              method: 'PUT',
              mode: 'cors',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                "logoXlg": imageUrl,
                "logoLg": imageUrl,
                "logoMd": imageUrl,
                "logoSm": imageUrl,
                "logoXs": imageUrl
              })
            })
            const data = await response.json()

            if (data.status === "success") {
              setIsUploading(false)
              setImageUploaded(true)
            }

          })
      })
        .catch(error => {
          setIsUploading(false)
          setFailed(true)
          console.log('error while uploading image', error)
        })

    } else {
      console.log("no img");
    }
  }
  console.log(image)

  return (
    <>
      <div className="container">
        <div className="step2-container" style={image || failed ? { display: "none" } : null}>
          <div className="step2">
            <Dropzone onDrop={onDrop} accept={"image/*"} />
            <div className="step2-heading">
              <h2>Drag and drop your logo here</h2>
            </div>
            <div className="step2-drop-image">
              {/* <Image src="/imageUpload.svg" width={150} height={150} alt="image upload" /> */}
              <img src={upload} alt="upload" />
            </div>
            <div className="step2-drop-text">
              Drag an image here or <span className='browse'>Browse <input type="file" className='get-img' onChange={handleFile} /></span>  for an image to upload
            </div>
          </div>
        </div>
        {isUploading ? <Step2a /> : null}
        {failed ? <Step2b setImage={setImage} setLoading={setLoading} setFailed={setFailed} /> : null}
        {imageUploaded ? <Step2c image={image} setImage={setImage} /> : null}
      </div>
    </>

  )
}