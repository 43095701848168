import { useState, useContext, useEffect } from 'react'
import { Slider, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import DataContext from '../../../store/DataContext';

export default function Step4({ setDisableBtn }) {

  const dataCtx = useContext(DataContext);
  //const [isActive, setIsActive] = useState(1)
  const [couponDescription, setCouponDescription] = useState('')
  const [durationType, setDurationType] = useState('days')
  const [duration, setDuration] = useState()
  const [cost, setCost] = useState()

  const [maxLimit, setMaxLimit] = useState(false)

  const marks = [
    { value: 0, label: '0', },
    { value: 1, label: '1', },
    { value: 2, label: '2', },
    { value: 3, label: '3', },
    { value: 4, label: '4', },
    { value: 5, label: '5', },
    { value: 6, label: '6', },
    { value: 7, label: '7', },
    { value: 8, label: '8', },
    { value: 9, label: '9', },
    { value: 10, label: '10', },
    { value: 11, label: '11', }
  ];

  useEffect(() => {
    dataCtx.setStep4({ ...dataCtx.step4, cost })
  }, [cost])

  useEffect(() => {
    setDisableBtn(false)
  }, [])



  function valueText(value) {
    return `${value} Rands`;
  }

  const calculateRand = (value) => {
    switch (dataCtx.step4.durationType) {
      case 'days':
        setCost(value * 80)
        return <div style={{ position: 'relative' }} ><span>{value * 80} Rands</span> <span className='slider-duration-type' >{dataCtx.step4.durationType}</span></div>;

      case 'weeks':
        setCost(value * 200)
        return <div style={{ position: 'relative' }} ><span>{value * 200} Rands</span> <span className='slider-duration-type' >{dataCtx.step4.durationType}</span></div>;

      case 'months':
        setCost(value * 350)
        return <div style={{ position: 'relative' }} ><span>{value * 350} Rands</span> <span className='slider-duration-type' >{dataCtx.step4.durationType}</span></div>;

      case 'years':
        setCost(value * 600)
        return <div style={{ position: 'relative' }} ><span>{value * 600} Rands</span> <span className='slider-duration-type' >{dataCtx.step4.durationType}</span></div>;

      default:
        break;
    }
  }

  const handleChangeDesc = (e) => {
    let desc = e.target.value

    if (desc.length > 100) {
      setMaxLimit(true)
    } else {
      setMaxLimit(false)
    }

    dataCtx.setStep4({ ...dataCtx.step4, couponDescription: desc })

  }

  const handleChangeDurationType = (e) => {
    // setDurationType(e.target.value)
    dataCtx.setStep4({ ...dataCtx.step4, durationType: e.target.value })
  }

  const handlePeriod = (e) => {
    // console.log(e)
    // setDuration(e.target.value)
    dataCtx.setStep4({ ...dataCtx.step4, duration: e.target.value })
  }

  console.log(dataCtx)
  return (
    <>
      <div className="step4-container">

        <div className="step4-header">
          <h1>Coupon expiration</h1>
          <h3>Please determine how long the coupon will be available</h3>
        </div>

        <div className="step4-slider">
          <Slider
            size="small"
            value={dataCtx.step4.duration}
            getAriaValueText={valueText}
            valueLabelDisplay="on"
            marks={marks}
            scale={calculateRand}
            onChange={handlePeriod}
            min={0}
            max={11}
          />
        </div>

        <div className="step4-radio-select">
          <FormControl>
            <RadioGroup value={dataCtx.step4.durationType} row>
              <FormControlLabel control={<Radio checked={dataCtx.step4.durationType == "days" ? true : false} onClick={handleChangeDurationType} value="days" />} label="days" />
              <FormControlLabel control={<Radio checked={dataCtx.step4.durationType == "weeks" ? true : false} onClick={handleChangeDurationType} value="weeks" />} label="weeks" />
              <FormControlLabel control={<Radio checked={dataCtx.step4.durationType == "months" ? true : false} onClick={handleChangeDurationType} value="months" />} label="months" />
              <FormControlLabel control={<Radio checked={dataCtx.step4.durationType == "years" ? true : false} onClick={handleChangeDurationType} value="years" />} label="years" />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="step4-coupon-desc">
          <h1>Coupon description</h1>
          <textarea style={{ resize: 'none' }} value={dataCtx.step4.couponDescription} onChange={handleChangeDesc} cols="50" rows="3" placeholder="Brief specific description of the deal."></textarea>
          <blockquote style={maxLimit ? { color: "#f00" } : null} >max 100 characters</blockquote>
        </div>

      </div>
    </>
  )
}