import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams, } from 'react-router-dom';

import { Button, MobileStepper } from "@mui/material";
import { CloseRounded, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

// import steps
import Step1 from '../Components/advertise/coupon/Step1';
import Step2 from '../Components/advertise/coupon/Step2';
import Step3 from '../Components/advertise/coupon/Step3';
import Step4 from '../Components/advertise/coupon/Step4';
import Step5 from '../Components/advertise/coupon/Step5';
import Step6 from '../Components/advertise/coupon/Step6';
import Step7 from '../Components/advertise/coupon/Step7';

import Step8 from '../Components/advertise/coupon/Step8';
import DataContext from '../store/DataContext';

const CreateCoupon = () => {

  const navigate = useNavigate();
  const params = useParams()

  const dataCtx = useContext(DataContext)

  const [activeStep, setActiveStep] = useState(null);
  const [disableBtn, setDisableBtn] = useState(true);
  const [pushData, setPushData] = useState(false)
  const [isDataEditing, setIsDataEditing] = useState(false)
  const [companyId, setCompanyId] = useState(false)
  const [couponId, setCouponId] = useState(false)


  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    setIsDataEditing(query.get('edit') == 'true' ? true : false)
    setCouponId(+query.get('couponId'))
    setCompanyId(+query.get('companyId'))
  }, [])

  useEffect(() => {
    setActiveStep(parseInt(params.id))
  }, [params])

  useEffect(() => {
    if (isDataEditing) {
      getCouponData()
    }
  }, [isDataEditing])

  const handleNext = () => {
    navigate(`/advertise/coupon/${params.term}/create-coupon/${activeStep + 1}?edit=false&companyId=${companyId}`)
    setPushData(true)
  };

  const handleBack = () => {
    if (activeStep == 2) {
      navigate(`/advertise/coupon/${params.term}/create-coupon/${activeStep - 1}?edit=false`)
    } else {
      navigate(`/advertise/coupon/${params.term}/create-coupon/${activeStep - 1}?edit=false&companyId=${companyId}`)
    }
  };

  const handleNextClick = e => {
    e.preventDefault()

    if (activeStep === 1) {
      createCompany()
    } else if (activeStep === 8) {
      createCouponPost()
    } else {
      handleNext()
    }

  }

  const renderSwitch = (step) => {
    switch (step) {
      case 1:
        return <Step1 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} pushData={pushData} />;
      case 2:
        return <Step2 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 3:
        return <Step3 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 4:
        return <Step4 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 5:
        return <Step5 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 6:
        return <Step6 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 7:
        return <Step7 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      case 8:
        return <Step8 isDataEditing={isDataEditing} setDisableBtn={setDisableBtn} setCompanyId={setCompanyId} companyId={companyId} />;
      default:
        return <Step1 />;
    }
  }

  const renderCompanyName = (step) => {
    switch (step) {
      case 1:
        return "Company Logo";
      case 2:
        return "Custom Deal";
      case 3:
        return "Coupon Expiration";
      case 4:
        return "Code and Website";
      case 5:
        return "T&C";
      case 6:
        return "Location";
      case 7:
        return "Categories";
      case 8:
        return "Redirect to Resume";
      default:
        return "Company Details";
    }
  }

  const createCouponPost = async () => {

    const payload = {
      "companyId": companyId,
      "coupon": dataCtx.step5.couponCode,
      "contactId": "1",
      "description": dataCtx.step4.couponDescription,
      "validFor": `${dataCtx.step4.duration} ${dataCtx.step4.durationType}`,
      "locations": dataCtx.step7.locations,
      "categories": dataCtx.step8.categories.map(i => i.id),
      "website": dataCtx.step5.couponWebsite,
      "mobile": dataCtx.step1.managerNumber,
      "email": dataCtx.step1.email,
      "onlineOnly": "yes",
      "countries": "2",
      "deal":dataCtx.step3.data.deal,
      "dealItem":dataCtx.step3.data.dealItem,
      "dealUnit": dataCtx.step3.data.dealUnit?.toUpperCase(),
      "termsAndConditions": dataCtx.step6.terms,
      "userScope": "agen"
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/coupon`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
      })
      const data = await res.json()

      if (data.status == 'success') {
        navigate(`/advertise/coupon/resume?companyId=${companyId}&couponId=${data.id}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const updateCouponPut = async () => {

    const payload = {
      "companyId": companyId,
      "coupon": dataCtx.step5.couponCode,
      "contactId": "1",
      "description": dataCtx.step4.couponDescription,
      "validFor": `${dataCtx.step4.duration} ${dataCtx.step4.durationType}`,
      "locations": dataCtx.step7.locations,
      "categories": dataCtx.step8.categories?.map(i => i.id),
      "website": dataCtx.step5.couponWebsite,
      "mobile": dataCtx.step1.managerNumber,
      "email": dataCtx.step1.email,
      "onlineOnly": "yes",
      "countries": "2",
      "deal":dataCtx.step3.data.deal,
      "dealItem":dataCtx.step3.data.dealItem,
      "dealUnit": dataCtx.step3.data.dealUnit?.toUpperCase(),
      "termsAndConditions": dataCtx.step6.terms,
      "userScope": "agen"
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/coupon/${couponId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
      })
      const data = await res.json()

      if (data.status == 'success') {
        navigate(`/advertise/coupon/resume?companyId=${companyId}&couponId=${couponId}`)
      }
    } catch (error) {
      console.log(error)
    }
  }


  const createCompany = async () => {

    const payload = {
      "companyName": dataCtx.step1.Cname,
      "companyWebsite": dataCtx.step1.Cname,
      "contact": dataCtx.step1.Cname,
      "email": dataCtx.step1.email,
      "mobile": dataCtx.step1.Cname,
      "description": "test rushi",
      "address": dataCtx.step1.ManagerAdd,
      "colour": "#f08231",
      "isGlobal": false,
      "keyWords": "Software Development, Web Dev",
      "userScope": "test",
      "message": "test rushi"
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company`, {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
      })
      const data = await res.json()

      if (data.status == 'success') {
        setCompanyId(data.id)
        navigate(`/advertise/coupon/${params.term}/create-coupon/${activeStep + 1}?edit=false&companyId=${data.id}`)
        dataCtx.setStep2({});
        dataCtx.setStep3({ activeTab: 1, data: { deal: 1, dealUnit: "Free", dealItem: 'T-Shirt' } });
        dataCtx.setStep4({ cost: 0, durationType: 'days', duration: 0, couponDescription: '' });
        dataCtx.setStep5({ couponCode: '', couponWebsite: '' });
        dataCtx.setStep6({ terms: [] });
        dataCtx.setStep7({ locations: [] });
        dataCtx.setStep8({ categories: [] });
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getCouponData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/coupon/${couponId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      })
      const data = await res.json()

      if (data.status == 'success') {
        console.log(data.data)
        dataCtx.setStep2()
        dataCtx.setStep3({ activeTab: 1, data: { deal: data.data?.deal, dealUnit: data.data?.dealUnit, dealItem: data.data?.dealItem } });
        dataCtx.setStep4({ cost: 0, durationType: 'days', duration: 0, couponDescription: 'test' });
        dataCtx.setStep5({ couponCode: data.data.coupon , couponWebsite: data.data.website });
        dataCtx.setStep6({ terms: data.data?.terms?.split(', ') });
        dataCtx.setStep7({ locations: data.data.locations });
        dataCtx.setStep8({ categories: data.data?.categories });
      }
    } catch (error) {
      console.log(error)
    }
  }

  const continueToResumePage = () => {
    updateCouponPut()
    
  }

  console.log(dataCtx)
  // console.log(isDataEditing)
  console.log("setDisableBtn", disableBtn)

  return (
    <>
      <div className="steps">
        {activeStep !== null ? renderSwitch(activeStep) : null}
      </div>
      <div className="stepper" style={isDataEditing ? { display: 'none' } : { display: 'block' }} >
        <div className="stepper-control">
          <div className="button">
            <Button onClick={handleBack} disabled={activeStep === 1} style={{ marginBottom: "0px" }}>
              <KeyboardArrowLeft /> Back
            </Button>
          </div>

          <div className="next-step">
            <p>next step</p>
            <h3>{renderCompanyName(activeStep)}</h3>
          </div>

          <div className="blue-btn button">
            <Button onClick={handleNextClick} disabled={disableBtn} style={{ marginBottom: "0px" }}> Continue <KeyboardArrowRight /> </Button>
          </div>
        </div>

        <MobileStepper variant="progress" steps={10} position="static" activeStep={activeStep} style={{ maxWidth: "100%" }} />
      </div>
      <div className="save-and-continue" style={!isDataEditing ? { display: 'none' } : { display: 'flex' }}>
        <Button disabled={disableBtn} className='save-continue-btn' onClick={continueToResumePage} >Save & Continue</Button>
      </div>
      <div className="deliva-logo">
        <div className="logo"></div>
        <div className="close-coupon"><CloseRounded /></div>
      </div>

    </>

  )
}

export default CreateCoupon