import React from 'react';

import DetailsDrawer from "./Components/DetailsDrawer";

import Thanks from './Components/advertise/Thanks'
import Resume from './Components/advertise/coupon/Resume'
import PricePlan from './Pages/PricePlan.jsx';


import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

import { DrawerContextProvider } from './store/DrawerContext.js';
import { DataContextProvider } from './store/DataContext.js';
import HelpWithCoupons from './Pages/HelpWithCoupons.jsx';
import HelpMe from './Pages/HelpMe.jsx';
import CreateCoupon from './Pages/CreateCoupon.jsx';

function App() {


  return (
    <div className="App">

      <DrawerContextProvider>
        <DataContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<PricePlan />} />
              <Route path="/price-plan" exact element={<PricePlan />} />
              <Route path="/advertise/:term/help" exact element={<HelpWithCoupons />} />
              <Route path="/advertise/:term/help-me" exact element={<HelpMe />} />
              <Route path="/advertise/coupon/:term/create-coupon/:id" exact element={<CreateCoupon />} />
              <Route path="/advertise/coupon/resume" exact element={<Resume />} />
              <Route path="/advertise/thanks" exact element={<Thanks />} />
            </Routes>
            <DetailsDrawer />
          </BrowserRouter>
        </DataContextProvider>
      </DrawerContextProvider>

    </div>
  );
}

export default App;
