import { Button } from "@mui/material";
import { NavLink } from "react-router-dom";

const PricePlan = () => {
  return (
    <div className="price-plan-container">

      <div className="price-plan-header">
        <h1>Pricing Plan</h1>
        {/* <h3>Lorem ipsum dolor sit amet.</h3> */}
      </div>

      <div className="price-plan">
        <div className="price-card">
          <div className="plan-name">
            <h3>Special Occasions</h3>
          </div>
          <div className="plan-duration">
            <h1>1-6</h1>
            <h3>days</h3>
          </div>
          <hr />
          <div className="plan-price">
            <p>STARTING FROM</p>
            <h2>R 80</h2>
          </div>
          <div className="button">
            <NavLink to="/advertise/special/help">
              <Button>Start Now</Button>
            </NavLink>
          </div>
        </div>

        <div className="price-card">
          <div className="plan-name">
            <h3>short term</h3>
          </div>
          <div className="plan-duration">
            <h1>1-3</h1>
            <h3>weeks</h3>
          </div>
          <hr />
          <div className="plan-price">
            <p>STARTING FROM</p>
            <h2>R 200</h2>
          </div>
          <div className="button">
            <NavLink to="/advertise/short-term/help">
              <Button>Start Now</Button>
            </NavLink>
          </div>
        </div>

        <div className="price-card">
          <div className="plan-name">
            <h3>mid term</h3>
          </div>
          <div className="plan-duration">
            <h1>1-11</h1>
            <h3>months</h3>
          </div>
          <hr />
          <div className="plan-price">
            <p>STARTING FROM</p>
            <h2>R 350</h2>
          </div>
          <div className="button">
            <NavLink to="/advertise/mid-term/help">
              <Button>Start Now</Button>
            </NavLink>
          </div>
        </div>

        <div className="price-card">
          <div className="plan-name">
            <h3 style={{ color: "#fff" }}>long term</h3>
          </div>
          <div className="plan-duration">
            <h1 style={{ color: "#fff" }} >1-2</h1>
            <h3 style={{ color: "#fff" }}>years</h3>
          </div>
          <hr style={{ color: "#fff" }} />
          <div className="plan-price">
            <p style={{ color: "#fff" }} >STARTING FROM</p>
            <h2 style={{ color: "#fff" }} >R 600</h2>
          </div>
          <div className="button">
            <NavLink to="/advertise/long-term/help">
              <Button>Start Now</Button>
            </NavLink>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PricePlan