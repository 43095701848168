import { useContext, useEffect, useState } from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import location from '../../../assets/img/ad/location.svg'

import MapPicker from 'react-google-map-picker'
import GoogleMapReact from 'google-map-react'
// import './map.css'
import { Checkbox, FormControlLabel, FormGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import DataContext from '../../../store/DataContext';
import { LocationOn } from '@mui/icons-material';

const Step7 = ({ setDisableBtn }) => {

  const dataCtx = useContext(DataContext)

  const DefaultLocation = { lat: 35.565, lng: 37.566 };
  const DefaultZoom = 10;

  const googleMapLocation = {
    address: '1600 Amphitheatre Parkway, Mountain View, california.',
    lat: 37.42216,
    lng: -122.08427,
  }

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [addressTitle, setAddressTitle] = useState('');
  const [address, setAddress] = useState('');
  const [locations, setLocations] = useState([])

  const [companyId, setCompanyId] = useState(0)

  const [mapLocation, setMapLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);




  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    setCompanyId(+query.get('companyId'))
    setDisableBtn(false)
  }, [])

  function handleChangeLocation(lat, lng) {
    setMapLocation({ lat: lat, lng: lng });
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  function handleResetLocation() {
    setDefaultLocation({ ...DefaultLocation });
    setZoom(DefaultZoom);
  }

  // useEffect(() => {
  //   dataCtx.setStep7({ locations: locations })
  // }, [locations])


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    height: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: '5px',
  };

  // console.log(mapLocation)

  const addAddress = (e) => {
    setAddress(e)
  }

  const addAddressTitle = (e) => {
    setAddressTitle(e)
  }

  const addAddressToArray = async () => {


    const payload = {
      "title": addressTitle,
      "email": "testEmail@google.com",
      "mobile": "0011223344",
      "address": address,
      "country": "India",
      "latitude": mapLocation.lat,
      "longitude": mapLocation.lng
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/company/${companyId}/location`, {
        method: 'POST',
        'Access-Control-Allow-Origin': '*',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + '' },
        body: JSON.stringify(payload)
      })
      const data = await res.json()

      if (data.status == 'success') {
        // const tempArray = locations
        // tempArray.push({
        //   title: addressTitle,
        //   address: address,
        //   // country: India,
        //   latitude: mapLocation.lat,
        //   longitude: mapLocation.lng
        // })
        // setLocations(tempArray)
        setAddress('')
        setAddressTitle('')
        handleCloseModal()
        dataCtx.setStep7({
          locations: [...dataCtx.step7.locations, {
            locationId: data.id,
            "title": addressTitle,
            "email": "testEmail@google.com",
            "mobile": "0011223344",
            "address": address,
            "country": "India",
            "latitude": mapLocation.lat,
            "longitude": mapLocation.lng
          }]
        })
        console.log(data)
      }
    } catch (error) {
      console.log(error)
    }


  }

  const deleteLocation = (index) => {

    const tempArray = dataCtx.step7.locations.slice()
    tempArray.splice(index, 1);
    dataCtx.setStep7({ locations: tempArray })
    // setLocations(tempArray)

  }

  const getLocationTableItem = (arr) => {
    if (arr?.length == 0) return
    return arr.map((item, index) => (
      <div key={index} className="location-item">
        <div className="address-title">{item.title}</div>
        <div className="address-address">{item.address}</div>
        <CloseRoundedIcon className="close" onClick={() => deleteLocation(index)} />
      </div>
    ))
  }

  const LocationPin = ({ text }) => (
    <div className="pin">
      <LocationOn className="pin-icon" />
      <p className="pin-text">{text}</p>
    </div>
  )

  // console.log(locations)
  console.log(dataCtx)

  return (
    <>
      <div className="container">
        <div className="step7-container">
          <div className="step7">
            <div className="step7-heading">
              <h1>Location</h1>
              <h3>Add locations that apply to your coupon</h3>
            </div>
            <div className="locations-table" style={dataCtx.step7.locations.length > 0 ? null : { display: 'none' }} >
              <div className="table-head">
                <div className="title">Title</div>
                <div className="address">Address</div>
              </div>
              <div className="table-body">
                {
                  getLocationTableItem(dataCtx.step7.locations)
                }
              </div>
            </div>
            {
              !dataCtx.step7.locations?.length
                ? <div className="step7-drop-image">
                  <img src={location} alt="location" />
                </div>
                : null
            }
            <div className="create-new" onClick={handleOpenModal} >
              <AddRoundedIcon /> <span>Add new location</span>
            </div>
          </div>
        </div>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="new-location-container">
              <div className="new-location">
                {/* <MapPicker defaultLocation={defaultLocation}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: '100%' }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  apiKey='AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8' /> */}
                <GoogleMapReact
                  bootstrapURLKeys={{ key: 'AIzaSyBDxzT4R7OVIu-rom3Pq1KNjTEOBLr2758' }}
                  defaultCenter={googleMapLocation}
                  defaultZoom={10}
                  onClick={(e) => console.log(e)}
                >
                  <LocationPin
                    lat={googleMapLocation.lat}
                    lng={googleMapLocation.lng}
                    text={googleMapLocation.address}
                  />
                </GoogleMapReact>
              </div>
              <div className="address-title-input">
                <div className="input input-address">
                  <label>ADDRESS</label>
                  <input value={address} type="text" placeholder='Enter Your Address' onChange={(e) => addAddress(e.target.value)} />
                </div>
                <div className="input">
                  <label>TITLE</label>
                  <input value={addressTitle} type="text" placeholder='Assign a title for your address' onChange={(e) => addAddressTitle(e.target.value)} />
                </div>
                <button className="blue-btn" onClick={() => addAddressToArray()} >Add</button>
              </div>
              <div className="btn-close">
                <CloseRoundedIcon onClick={handleCloseModal} />
              </div>
            </div>
          </Box>
        </Modal>

      </div >

    </>

  )
}

export default Step7