import { useEffect, useState } from 'react'
import { Button, TextField, MobileStepper } from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

const HelpMe = () => {

  const params = useParams()

  const [fullName, setFullName] = useState("")
  const [phone, setPhone] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [couponDesc, setCouponDesc] = useState("")
  const [term, setTerm] = useState("")

  useEffect(() => {
    setTerm(params.term)
  }, [params])

  const changeFullName = (e) => {
    let value = e.target.value
    setFullName(value)
  }

  const changePhoneNumber = (e) => {
    let value = e.target.value
    setPhone(value)
  }

  const changeCompanyName = (e) => {
    let value = e.target.value
    setCompanyName(value)
  }

  const changeEmail = (e) => {
    let value = e.target.value
    setEmail(value)
  }

  const changeCouponDesc = (e) => {
    let value = e.target.value
    setCouponDesc(value)
  }

  return (
    <div className="container">
      <div className="help-container">
        <div className="help-me">
          <div className="help-heading">
            <h1>We’d love hear from you</h1>
            <h2>Send us a message and we’ll respond as soon as possible</h2>
          </div>
          <div className="help-input">
            <div className="input-first">
              <TextField required label="FULL NAME" value={fullName} variant="standard" onChange={changeFullName} />
              <TextField required label="PHONE NUMBER" value={phone} variant="standard" onChange={changePhoneNumber} />
            </div>

            <div className="input-second">
              <TextField required label="COMPANY NAME" value={companyName} variant="standard" onChange={changeCompanyName} />
              <TextField required label="EMAIL" value={email} variant="standard" onChange={changeEmail} />
            </div>

            <textarea onChange={changeCouponDesc} style={{ resize: 'none' }} value={couponDesc} cols="100" rows="5" placeholder="Tell us a little what you have in mind for your coupon."></textarea>

            <div className="blue-btn button">
              <NavLink to="/advertise/Thanks">
                <button className='btn' >Send</button>
              </NavLink>
            </div>

          </div>
        </div>

        <div className="logo-home"></div>
        <div className="back-link-container">
          <NavLink to={`/advertise/${term}/help`}><a className="back-link"> <ArrowBackIosNewRoundedIcon /> Back </a></NavLink>
        </div>

      </div>
    </div>
  )
}

export default HelpMe