import { useState, useContext, useEffect } from 'react'
import DataContext from '../../../store/DataContext';

import { CloseRounded } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Divider, IconButton, Menu, MenuItem } from '@mui/material';

import LeviLogo from '../../../assets/img/ad/levi-logo.svg'
import BurgerLogo from '../../../assets/img/ad/burgur-king-logo.svg'
import { useNavigate } from 'react-router-dom';

export default function Stepper() {

  const navigate = useNavigate();
  const dataCtx = useContext(DataContext);

  const [companyId, setCompanyId] = useState(null)
  const [couponId, setCouponId] = useState(null)
  const [couponImage, setCouponImage] = useState(null)
  const [couponDescription, setCouponDescription] = useState(null)
  const [couponDuration, setCouponDuration] = useState(null)
  const [couponDeal, setCouponDeal] = useState(null)
  const [couponDealUnit, setCouponDealUnit] = useState(null)
  const [couponTerms, setCouponTerms] = useState([])
  const [couponWebsite, setCouponWebsite] = useState(null)
  const [couponCode, setCouponCode] = useState(null)
  const [couponLocations, setCouponLocations] = useState([])
  const [couponCategories, setCouponCategories] = useState([])
  const [editStep, setEditStep] = useState(1)
  const [menuState, setMenuState] = useState(null);
  // const [openMenu, setOpenMenu] = useState(false);
  const openMenu = Boolean(menuState)

  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search)
    setCompanyId(+query.get('companyId'))
    setCouponId(+query.get('couponId'))
  }, [])

  useEffect(() => {
    getCouponData()
  }, [couponId])

  let bgColorForSpan = ''
  let colorForSpan = ''

  function getRandomColor(name = 'abc') {
    const first = name?.split(' ')[0]
    const firstAlphabet = first?.charAt(0).toLowerCase();
    const asciiCode = firstAlphabet?.charCodeAt(0);
    const colorNum = asciiCode?.toString() + asciiCode.toString() + asciiCode.toString();
    var num = Math.round(0xffffff * parseInt(colorNum));
    var r = num >> 16 & 255;
    var g = num >> 8 & 255;
    var b = num & 255;
    bgColorForSpan = `rgb(${r}, ${g}, ${b}, 0.1)`
    colorForSpan = `rgb(${r}, ${g}, ${b}, 1)`
    return <span style={{ background: bgColorForSpan, color: colorForSpan }} className="category" >{name}</span>
  }

  const getCouponData = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/coupon/${couponId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + '' },
      })
      const data = await res.json()

      if (data.status == 'success') {
        console.log(data.data)
        setCouponCategories(data.data?.categories)
        setCouponLocations(data.data?.locations)
        setCouponCode(data.data?.coupon)
        setCouponWebsite(data.data?.website)
        setCouponDeal(data.data?.deal)
        setCouponDealUnit(data.data?.dealUnit)
        setCouponDescription(data.data?.description)
        setCouponTerms(data.data?.terms?.split(','))
      }
    } catch (error) {
      console.log(error)
    }

  }


  const redirectToStep = (step) => {
    navigate(`/advertise/coupon/short-term/create-coupon/${step}?edit=true&companyId=${companyId}&couponId=${couponId}`)
  }

  const handleCloseMenu = () => {
    setMenuState(null);
  }

  const handleClick = (event) => {
    setMenuState(event.currentTarget);
  };

  console.log(companyId)
  console.log(couponId)


  return (
    <>
      <div className="resume-container">
        <div className="coupon-details-container-2">
          <div className="resume-logo full-width">
            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(1)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 1} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(2)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <img src={BurgerLogo} alt="coupon-logo" />
          </div>
          <div className="resume-company-name full-width">

            <h1>{dataCtx.step1.Cname}</h1>
            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(2)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 2} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(1)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>
          </div>
          <div className="resume-mm-details full-width">
            <div className="mm-details">
              <div className="block-1">
                <div className="bold-title">Marketing manager name</div>
                <div className="light-title">{dataCtx.step1.managerName}</div>
              </div>
              <div className="block-2">
                <div className="bold-title">Marketing Manager&apos;s Address </div>
                <div className="light-title">{dataCtx.step1.managerAdd}</div>
              </div>
            </div>
            <div className="mm-details">
              <div className="block-1">
                <div className="bold-title">email address</div>
                <div className="light-title">{dataCtx.step1.email}</div>
              </div>
              <div className="block-2">
                <div className="bold-title">COMPANY WEBSITE</div>
                <div className="light-title">{dataCtx.step1.Cwebsite}</div>
              </div>

            </div>
            <div className="mm-details">
              <div className="block-1">
                <div className="bold-title">Marketing Manager Phone Number</div>
                <div className="light-title">{dataCtx.step1.ManagerNo}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="coupon-coupon-container-2">
          <div className="coupon-card-container">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(3)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 3} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(3)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon">
              <div className="coupon-left">
                <h1>{couponDeal}</h1>
                {/* <span>{couponDealUnit}</span> */}
                <h2>{couponDealUnit}</h2>
              </div>
              <Divider orientation="vertical" />
              <img className='coupon-right' src={BurgerLogo} alt="coupon-logo" />
            </div>
          </div>

          <div className="coupon-card-container">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(4)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 4} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(4)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon-desc">
              <h1>Coupon description</h1>
              <h3>{couponDescription}</h3>
            </div>
          </div>

          <div className="coupon-card-container">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(5)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 5} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(4)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon-details">
              <div className="coupon-left">
                <h1>10</h1>
                <h3>Months</h3>
                <span>mid term</span>
              </div>

              <Divider orientation="vertical" />

              <div className="coupon-right">
                <h1>{couponDeal}</h1>
                <h3>{couponDealUnit}</h3>
              </div>
            </div>
          </div>

        </div>

        <div className="coupon-terms-container">
          <div className="main-title full-width">
            <h2>Terms & conditions</h2>

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(6)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 6} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(6)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

          </div>
          <div className="resume-terms full-width">
            <div className="terms">
              {
                couponTerms?.map((term, i) => (
                  <div className="term" key={i}>
                    <div className="term-number">{i + 1}</div>
                    <div className="term-text">{term}</div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        <div className="coupon-website-code-container">

          <div className="coupon-website-container">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(7)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 7} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(5)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon-website">
              <h1>Coupon website</h1>
              <h3>{couponWebsite}</h3>
            </div>
          </div>

          <div className="coupon-code-container">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(8)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 8} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(5)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon-code">
              <h2>Coupon code</h2>
              <h1>{couponCode}</h1>
            </div>
          </div>
        </div>


        <div className="coupon-location-container">
          <div className="main-title full-width">
            <h2>Locations</h2>

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(9)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 9} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(7)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

          </div>
          <div className="resume-location full-width">
            <div className="locations">

              {
                couponLocations?.length > 0
                  ? couponLocations?.map((item, index) => (
                    <div className="location">
                      <div className="location-number">{index + 1}</div>
                      <div className="location-text">
                        <span className='location-title'>{item.title}</span>
                        <span className='location-address'>{item.address}</span>
                      </div>
                    </div>
                  ))
                  : null
              }
            </div>
          </div>
        </div>

        <div className="coupon-categories-container">
          <div className="coupon-categorie">

            <div className="edit">
              <IconButton aria-label="more" id="long-button" aria-controls={openMenu ? 'long-menu' : undefined} aria-expanded={openMenu ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} >
                <MoreVertIcon onClick={() => setEditStep(10)} />
              </IconButton>
              <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button', }} anchorEl={menuState} open={openMenu && editStep === 10} onClose={handleCloseMenu} >
                <MenuItem onClick={() => redirectToStep(8)}>
                  Edit
                </MenuItem>
              </Menu>
            </div>

            <div className="coupon-categories">
              <div className="title">Categories</div>

              <div className="categories">
                {
                  couponCategories?.map((c) => {
                    return getRandomColor(c.title)
                  })
                }
              </div>
            </div>
          </div>

        </div>


        <div className="pay-container">
          <div className="pay">
            <div className='pay-button'>Confirm <br /> & Pay</div>
          </div>
        </div>


      </div>

      <div className="deliva-logo">
        <div className="logo"></div>
        <div className="close-coupon"><CloseRounded /></div>
      </div>

    </>

  )
}