import loading from '../../../assets/img/ad/loading.gif'
export default function Step2a () {

    return (
    <>
        <div className="step2a-container">
          <div className="step2a">
            <div className="step2a-loader">
              <img src={loading} alt="loading" />
              <span className="loading">Loading...</span>
            </div>
          </div>
        </div>
    </>

    )
  }