import React, { useState, useEffect, useContext } from "react";
import { TextField } from "@mui/material";
import DataContext from '../../../store/DataContext';

export default function Step1({ setDisableBtn }) {

  const urlRegex = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

  const dataCtx = useContext(DataContext);

  // const [companyName, setCompanyName] = useState("")
  // const [email, setEmail] = useState("")
  // const [managerName, setManagerName] = useState("")
  // const [managerAddress, setManagerAddress] = useState("")
  // const [managerNumber, setManagerNumber] = useState("")
  // const [companyWebsite, setCompanyWebsite] = useState("")

  const [emailNotValid, setEmailNotValid] = useState(false)
  const [websiteNotValid, setWebsiteNotValid] = useState(false)
  const [companyNameNotValid, setCompanyNameNotValid] = useState(false)
  const [mangerNameNotValid, setMangerNameNotValid] = useState(false)
  const [phoneNumberNotValid, setPhoneNumberNotValid] = useState(false)
  const [managerAddressNotValid, setManagerAddressNotValid] = useState(false)


  useEffect(() => {

    if (
      dataCtx.step1.companyName.trim() !== '' &&
      dataCtx.step1.email.trim() !== '' &&
      dataCtx.step1.managerName.trim() !== '' &&
      dataCtx.step1.managerAddress.trim() !== '' &&
      dataCtx.step1.managerNumber.trim() !== '' &&
      dataCtx.step1.companyWebsite.trim() !== '' &&
      !emailNotValid &&
      !websiteNotValid &&
      !companyNameNotValid &&
      !mangerNameNotValid &&
      !phoneNumberNotValid &&
      !managerAddressNotValid
    ) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }

  }, [dataCtx])


  // useEffect(() => {
  //   setCompanyName(dataCtx.step1.companyName)
  //   setEmail(dataCtx.step1.email)
  //   setManagerName(dataCtx.step1.managerName)
  //   setManagerAddress(dataCtx.step1.managerAddress)
  //   setManagerNumber(dataCtx.step1.managerNumber)
  //   setCompanyWebsite(dataCtx.step1.companyWebsite)
  // }, [])


  const changeCompanyName = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, companyName: value })
    // dataCtx.step1.companyName = value
    if (value.trim() !== '') {
      setCompanyNameNotValid(false)
    } else {
      setCompanyNameNotValid(true)
      // return true
    }
  }

  const changeEmail = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, email: value })
    // dataCtx.step1.email = value
    if (/(([^\s@&&s@]+\.[^\s@]+))$/.test(value) && value.trim() !== '') {
      setEmailNotValid(false)
    } else {
      setEmailNotValid(true)
      return true
    }
  }

  const changeManagerName = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, managerName: value })
    // dataCtx.step1.managerName = value
    if (value.trim() !== '') {
      setMangerNameNotValid(false)
    } else {
      setMangerNameNotValid(true)
      return true
    }
  }

  const changeManagerAddress = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, managerAddress: value })
    // dataCtx.step1.managerAddress = value
    if (value.trim() !== '') {
      setManagerAddressNotValid(false)
    } else {
      setManagerAddressNotValid(true)
      return true
    }
  }

  const changeManagerNumber = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, managerNumber: value })
    // dataCtx.step1.managerNumber = value
    if (value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && value.trim() !== '') {
      setPhoneNumberNotValid(false)
    } else {
      setPhoneNumberNotValid(true)
      return true
    }
  }

  const changeCompanyWebsite = (e) => {
    let value = e.target.value
    dataCtx.setStep1({ ...dataCtx.step1, companyWebsite: value })
    // dataCtx.step1.companyWebsite = value
    if (urlRegex.test(value) && value.trim() !== '') {
      setWebsiteNotValid(false)
    } else {
      setWebsiteNotValid(true)
      return null
    }
  }

  // const submit = () => {
  //   dataCtx.setStep1({
  //     companyName: companyName,
  //     email: email,
  //     managerName: managerName,
  //     managerAddress: managerAddress,
  //     managerNumber: managerNumber,
  //     companyWebsite: companyWebsite
  //   })
  //   console.log(dataCtx.step1)
  // }



  return (
    <>
      <div className="container">
        <div className="step1-container">
          <div className="step1">
            <div className="step1-heading">
              <h1>Company Details</h1>
            </div>
            <div className="step1-input">

              {/* <form action="#0">

              <div>
                <input type="text" id="first_name" name="first_name" required placeholder=" " />
                <label for="first_name">First Name</label>
              </div>

              <div>
                <input type="text" id="last_name" name="last_name" required placeholder=" " />
                <label for="last_name">Last Name</label>
              </div>

              <div>
                <input type="email" id="email" name="email" required placeholder=" " />
                <label for="email">Email Address</label>
                <div class="requirements">
                  Must be a valid email address.
                </div>
              </div>

              <div>
                <input type="password" id="password" name="password" required placeholder=" " pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}" />
                <label for="password">Password</label>
                <div class="requirements">
                  Your password must be at least 6 characters as well as contain at least one uppercase, one lowercase, and one number.
                </div>
              </div>

            </form> */}

              <div className="input-first">
                <TextField error={companyNameNotValid} helperText={companyNameNotValid ? "Please enter a company name" : null} style={{ marginTop: "20px" }} onChange={changeCompanyName} value={dataCtx.step1.companyName} required label="COMPANY NAME" variant="standard" />
                <TextField error={emailNotValid} helperText={emailNotValid ? "Please enter a valid email" : null} style={{ marginTop: "20px" }} onChange={changeEmail} value={dataCtx.step1.email} required label="EMAIL ADDRESS" variant="standard" />
              </div>

              <div className="input-second">
                <TextField error={mangerNameNotValid} helperText={mangerNameNotValid ? "Please enter a manager name" : null} style={{ marginTop: "20px" }} onChange={changeManagerName} value={dataCtx.step1.managerName} required label="MARKETING MANAGER NAME" variant="standard" />
                <TextField error={managerAddressNotValid} helperText={managerAddressNotValid ? "Please add address" : null} style={{ marginTop: "20px" }} onChange={changeManagerAddress} value={dataCtx.step1.managerAddress} required label="MARKETING MANAGER'S ADDRESS " variant="standard" />
              </div>

              <div className="input-third">
                <TextField error={phoneNumberNotValid} helperText={phoneNumberNotValid ? "Please add a phone number" : null} onChange={changeManagerNumber} value={dataCtx.step1.managerNumber} required label="MARKETING MANAGER PHONE NUMBER" className="add-margin" variant="standard" />
                <TextField error={websiteNotValid} helperText={websiteNotValid ? "include full address with https://" : null} onChange={changeCompanyWebsite} value={dataCtx.step1.companyWebsite} style={{ marginTop: "21px" }} required label="COMPANY WEBSITE" variant="standard" />
              </div>
            </div>

          </div>
        </div>
      </div>

    </>

  )
}

