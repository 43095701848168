import React, { useState, useEffect, useCallback } from 'react';

let logoutTimer;

const AuthContext = React.createContext({
  token: '',
  userId: '',
  userName: '',
  email: '',
  scope: '',
  isLoggedIn: false,
  login: (token, userId, userName, email, scope, tokenExpiration) => {},
  logout: () => {}
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime               = new Date().getTime();
  const adjExpirationTime         = new Date(expirationTime).getTime();

  const remainingDuration         = adjExpirationTime - currentTime;

  return remainingDuration;
}

const retrieveStoredToken = () => {
  const storedToken               = localStorage.getItem('token');
  const storedTokenExpiration     = localStorage.getItem('tokenExpiration');

  const remainingTime             = calculateRemainingTime(storedTokenExpiration);

  if(remainingTime <= 1000) {

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('scope');

    return null;
  }
  
  return {
    token: storedToken,
    duration: remainingTime
  };
}

export const AuthContextProvider = (props) => {

  const tokenData                 = retrieveStoredToken();
  let initialToken;
  if(tokenData) {
    initialToken                  = tokenData.token;
  }
  const initialUserId             = localStorage.getItem('userId');
  const initialUserName           = localStorage.getItem('userName');
  const initialEmail              = localStorage.getItem('email');
  const initialScope              = localStorage.getItem('scope');

  const [token, setToken]         = useState(initialToken);
  const [userId, setUserId]       = useState(initialUserId);
  const [userName, setUserName]   = useState(initialUserName);
  const [email, setEmail]         = useState(initialEmail);
  const [scope, setScope]         = useState(initialScope);
  
  const userIsLoggedIn            = !!token;

  const logoutHandler = useCallback(() => {
    
    setToken(null);
    setUserId(null);
    setUserName(null);
    setEmail(null);
    setScope(null);

    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('email');
    localStorage.removeItem('scope');

    if(logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  useEffect(() => {
    if(tokenData) {
      logoutTimer                 = setTimeout(logoutHandler, tokenData.duration);
    }
  }, [tokenData, logoutHandler]);

  const loginHandler = (token, userId, userName, email, scope, tokenExpiration) => {
    
    setToken(token);
    setUserId(userId);
    setUserName(userName);
    setEmail(email);
    setScope(scope);

    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpiration', tokenExpiration);
    localStorage.setItem('userId', userId.toString());
    localStorage.setItem('userName', userName);
    localStorage.setItem('email', email);
    localStorage.setItem('scope', scope);

    const remainingTime           = calculateRemainingTime(tokenExpiration);

    logoutTimer                   = setTimeout(logoutHandler, remainingTime);

  }

  const contextValue = {
    token: token,
    userId: userId,
    userName: userName,
    email: email,
    scope: scope,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler
  }
  console.log(contextValue);
  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
}

export default AuthContext;