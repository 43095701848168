import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function Step2c ({ image, setImage }) {

  const handleClearImg = () => {
    setImage(null)
  }

    return (
    <>
      <div className="step2c-container">
        <div className="step2c">
          <div className="step2c-heading">
            <h2>Company logo</h2>
          </div>
          <div className="step2c-drop-image">
            <CheckCircleOutlineRoundedIcon />
          </div>
          <div className="step2c-drop-text">
            <h3>Your logo is loaded</h3>
            <div className="file-name"> <span>{image ? image.name : null}</span> <CloseRoundedIcon onClick={handleClearImg} /> </div> 
          </div>
         </div>
        </div>
    </>

    )
  }