import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import Dropzone from '../../ImageDropdown/Dropzone';
import { useCallback } from 'react';

export default function Step2b({ setFailed, setImage, setLoading }) {

    // get dropped images
    const onDrop = useCallback((acceptedFiles) => {
      setImage(acceptedFiles[0]);
      // acceptedFiles.map((file) => {
      //   const reader = new FileReader();
      //   reader.onload = function (e) {
      //     setImage(e.target.result);
      //   };
      //   reader.readAsDataURL(file);
      //   return file;
      // });
    }, []);

  const handleFile = (e) => {
    let img = e.target.files[0];
    console.log(img)
    if (img.type === "image/png" || img.type === "image/jpeg") {
      setLoading(true)
      setImage(img);
      setLoading(false)
      setFailed(false)
    } else {
      setFailed(true)
    }
  }

  return (
    <>
      <div className="step2b-container">
        <div className="step2b">
        <Dropzone onDrop={onDrop} accept={"image/*"} />
          <div className="step2b-heading">
            <h2>Oops !</h2>
            <span>Image format not supported</span>
          </div>
          <div className="step2b-drop-image">
            <ErrorOutlineRoundedIcon />
          </div>
          <div className="step2b-drop-text">
            <h3>Only JPG or PNG</h3>
            <div>Drag an image here or <span className='browse'>Browse <input type="file" className='get-img' onChange={handleFile} /></span> for an image to upload</div>
          </div>
        </div>
      </div>
    </>

  )
}