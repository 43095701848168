import { useState, useContext, useEffect } from 'react'
import DataContext from '../../../store/DataContext';

import { Input } from "@mui/material";
import couponCodeImg from "../../../assets/img/ad/coupon_code.svg"
import couponWebsiteImg from "../../../assets/img/ad/coupon_website.svg"

export default function Step5({ setDisableBtn }) {

  const urlRegex = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/mg;

  const dataCtx = useContext(DataContext);

  const [couponCode, setCouponCode] = useState('')
  const [couponWebsite, setCouponWebsite] = useState('')
  const [websiteNotValid, setWebsiteNotValid] = useState(false)
  const [codeNotValid, setCodeNotValid] = useState(false)


  useEffect(() => {

    if (dataCtx.step5.couponCode?.trim() !== '') {
      if (/^[0-9A-Z]+$/mg.test(dataCtx.step5.couponCode)) {
        setCodeNotValid(false)
      } else {
        setCodeNotValid(true)
      }
    } else {
      setCodeNotValid(true)
    }

  }, [dataCtx.step5.couponCode])

  useEffect(() => {

    if (dataCtx.step5.couponWebsite?.trim() !== '') {
      if (urlRegex.test(dataCtx.step5.couponWebsite)) {
        setWebsiteNotValid(false)
      } else {
        setWebsiteNotValid(true)
      }
    } else {
      setWebsiteNotValid(true)
    }

  }, [dataCtx.step5.couponWebsite])

  useEffect(() => {
    checkNextBtn()
  }, [websiteNotValid, codeNotValid])

  useEffect(() => {

    setDisableBtn(true)
    setWebsiteNotValid(false)
    setCodeNotValid(false)

  }, [])

  // useEffect(() => {

  //   if (dataCtx.step5.couponWebsite.trim() !== '' && dataCtx.step5.couponCode.trim() !== '' && !websiteNotValid && !codeNotValid) {
  //     setDisableBtn(false)
  //   } else {
  //     setDisableBtn(true)
  //   }

  // }, [dataCtx])

  const checkNextBtn = () => {
    if (dataCtx.step5.couponWebsite?.trim() !== '' && dataCtx.step5.couponCode?.trim() !== '' && !websiteNotValid && !codeNotValid) {
      setDisableBtn(false)
    } else {
      setDisableBtn(true)
    }
  }




  const handleChangeCode = (e) => {
    // let value = e.target.value
    // setCouponCode(value)
    dataCtx.setStep5({ ...dataCtx.step5, couponCode: e.target.value })
    // if (/^[0-9A-Z]+$/m.test(code)) {
    //   dataCtx.step5 = {
    //     couponCode: code,
    //     couponWebsite: dataCtx.step5?.couponWebsite ? dataCtx.step5?.couponWebsite : null
    //   }
    // }
  }


  const handleChangeWebsite = (e) => {
    // let value = e.target.value
    // setCouponWebsite(value)
    dataCtx.setStep5({ ...dataCtx.step5, couponWebsite: e.target.value })
  }

  // console.log(dataCtx)
  console.log("websiteNotValid", websiteNotValid)
  console.log('codeNotValid', codeNotValid)

  return (
    <>
      <div className="step5-container">
        <div className="step5-part1">
          <div className="heading">
            <h1>Coupon Code</h1>
            <h3>The coupon code representing <br /> your offline and/or online offer. </h3>
          </div>
          <div className="image">
            <img src={couponCodeImg} alt="coupon code" />
          </div>
          <div className="input">
            <label htmlFor="couponCode">COUPON CODE</label>
            <Input error={codeNotValid} value={dataCtx.step5.couponCode} id="couponCode" placeholder="Ex: DELIVA2020" onChange={(e) => handleChangeCode(e)} />
            {codeNotValid ? <span className="helper-text">It must be ALL CAPS without any spaces or <br /> special characters.</span> : <span  className="helper-text">&nbsp;</span>}
          </div>
        </div>

        <div className="step5-part2">
          <div className="heading">
            <h1>Coupon Website</h1>
            <h3>IF you have a website to claim the <br /> coupon please add it. </h3>
          </div>
          <div className="image">
            <img src={couponWebsiteImg} alt="coupon website" />
          </div>
          <div className="input">
            <label htmlFor="couponCode">WEBSITE</label>
            <Input error={websiteNotValid} value={dataCtx.step5.couponWebsite} id="couponCode" placeholder="Ex: http://deliva.com" onChange={(e) => handleChangeWebsite(e)} />
            {websiteNotValid ? <span className="helper-text">include full address with http:// <br /></span> : <span  className="helper-text">&nbsp;</span>}
          </div>
        </div>
      </div>

    </>
  )
}

